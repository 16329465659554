import { Badge } from '@shopify/polaris';
import { SessionStatus } from '~/graphql/sdk';

const SessionStatusBadge = ({ status }: { status: SessionStatus }) => {
  switch (status) {
    case SessionStatus.Started:
      return <Badge progress="incomplete">Started</Badge>;
    case SessionStatus.Open:
      return (
        <Badge progress="partiallyComplete" status="warning">
          Open
        </Badge>
      );
    case SessionStatus.Completed:
      return (
        <Badge progress="complete" status="new">
          Completed
        </Badge>
      );
    case SessionStatus.Converted:
      return (
        <Badge progress="complete" status="success">
          Converted
        </Badge>
      );
    default:
      return null;
  }
};

export default SessionStatusBadge;
