import { ReactNode } from 'react';
import { Loading } from '@shopify/app-bridge-react';

interface LazyLoadProps {
  children?: ReactNode;
}

export const LazyLoad = ({ children }: LazyLoadProps) => (
  <>
    <Loading />
    {children}
  </>
);
