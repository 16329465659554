import AnalyticPageContainer, {
  EnumAnalyticPages,
} from '~/components/AnalyticsCommon';
import SessionTable from '~/components/SessionsTable';

export const AnalyticsSessions = () => (
  <AnalyticPageContainer
    title={EnumAnalyticPages.SESSIONS}
    AnalyticComponent={SessionTable}
  />
);

export default AnalyticsSessions;
