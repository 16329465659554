import { useMemo } from 'react';
import { ActionList, Card, Layout } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';

const AnalyticNavMenu = () => {
  const location = useLocation();

  const items = useMemo(() => {
    return [
      {
        url: '/analytics',
        content: 'Metrics',
      },
      {
        url: '/analytics/sessions',
        content: 'Sessions',
      },
      {
        url: '/analytics/submissions',
        content: 'Submissions',
      },
    ].map((item) => ({ ...item, active: item.url === location.pathname }));
  }, [location]);

  return (
    <Layout.Section secondary>
      <Card title="Analytics">
        <ActionList
            items = {items}
        />
      </Card>
    </Layout.Section>
  );
};

export default AnalyticNavMenu;
