import { lazy, Suspense } from 'react';
import {
  Layout,
  SkeletonPage,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';

import { LazyLoad } from '~/components';

const Component = lazy(() => import('~/containers/Integrations'));

export default (props) => (
  <Suspense
    fallback={
      <LazyLoad>
        <SkeletonPage>
          <Layout>
            <Layout.AnnotatedSection title={<SkeletonDisplayText />}>
              <Card sectioned>
                <SkeletonBodyText lines={4} />
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        </SkeletonPage>
      </LazyLoad>
    }
  >
    <Component {...props} />
  </Suspense>
);
