import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import qs from 'query-string';
import config from '~/config';
import { useShopOrigin } from '~/hooks/useShopOrigin';

const useOpenFlowBuilder = () => {
  const app = useAppBridge();
  const shopOrigin = useShopOrigin();

  return (id, accessToken) =>
    app.dispatch(
      Redirect.toRemote({
        url: `${config.apiUrl}/auth/ott/${accessToken}?${qs.stringify({
          redirectUrl: `${
            process.env.NODE_ENV === 'development'
              ? `https://${window.location.hostname}/flow-builder`
              : process.env.REACT_APP_FLOW_BUILDER_URL
          }/${shopOrigin}/${id}`,
        })}`,
      }),
    );
};

export default useOpenFlowBuilder;
