import { lazy, Suspense } from 'react';
import {
  SkeletonPage,
  Spinner,
  Card,
  SkeletonBodyText,
} from '@shopify/polaris';

import { LazyLoad } from '~/components';

const Component = lazy(() => import('~/containers/FlowsOverview'));

export default (props) => (
  <Suspense
    fallback={
      <LazyLoad>
        <SkeletonPage title="Flows" primaryAction fullWidth>
          <Card sectioned>
            <Card.Section>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '-16px',
                }}
              >
                <div style={{ width: '80px', marginRight: '12px' }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <div style={{ width: '80px' }}>
                  <SkeletonBodyText lines={1} />
                </div>
              </div>
            </Card.Section>

            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>

            <Card.Section>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '200px',
                }}
              >
                <Spinner
                  accessibilityLabel="Loading flows overview"
                  size="large"
                />
              </div>
            </Card.Section>
          </Card>
        </SkeletonPage>
      </LazyLoad>
    }
  >
    <Component {...props} />
  </Suspense>
);
