import { useState } from 'react';
import { Button, Card, Modal, Stack } from '@shopify/polaris';
import { ShopcodesMajor } from '@shopify/polaris-icons';
import QRCode from 'react-qr-code';
import { useToast } from '~/lib';

interface QRShareProps {
  slug: string;
  title: string;
  url: string;
}

export const QRShare = ({ slug, title, url }: QRShareProps) => {
  const toast = useToast();

  const [isOpen, setOpen] = useState(false);

  const downloadCode = () => {
    try {
      const svg = document.getElementById('qrcode');

      if (!svg) {
        throw new Error();
      }

      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new Error();
      }

      const img = new Image();

      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');

        const downloadLink = document.createElement('a');
        downloadLink.download = `${slug}-qr`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };

      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    } catch (e) {
      toast({ content: 'Failed to download', error: true });
    }
  };

  return (
    <Modal
      small
      activator={
        <Button
          icon={ShopcodesMajor}
          plain
          onClick={() => {
            setOpen(true);
          }}
        />
      }
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      title="Get the QR code"
      primaryAction={{
        content: 'Download QR code',
        onAction: downloadCode,
      }}
      secondaryActions={[
        {
          content: 'Close',
          onAction: () => {
            setOpen(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical spacing="loose">
          <p>Scan the QR code to open the flow.</p>

          <Stack alignment="center" distribution="center">
            <Card>
              <Card.Section>
                <QRCode id="qrcode" value={url} title={title} />
              </Card.Section>
            </Card>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
