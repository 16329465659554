import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Chart } from './Chart';

interface SalesProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const Sales = ({ flowContainerId, selectedDates }: SalesProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsRevenueSales', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsRevenueSales({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsRevenueSales),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Chart
      data={data}
      isLoading={isLoading}
      selectedDates={selectedDates}
      accessibilityLabel="Loading sales analytics"
      subtitle="Sales over time"
      title="Sales"
      prices
    />
  );
};
