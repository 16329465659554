import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Chart } from './Chart';

interface CollectedEmailsProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const CollectedEmails = ({
  flowContainerId,
  selectedDates,
}: CollectedEmailsProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsCollectedEmails', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsCollectedEmails({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsCollectedEmails),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Chart
      data={data}
      isLoading={isLoading}
      selectedDates={selectedDates}
      accessibilityLabel="Loading email analytics"
      subtitle="Emails collected over time"
      title="Emails collected"
    />
  );
};
