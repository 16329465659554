import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Chart } from './Chart';

interface OrdersProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const Orders = ({ flowContainerId, selectedDates }: OrdersProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsRevenueOrders', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsRevenueOrders({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsRevenueOrders),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Chart
      data={data}
      isLoading={isLoading}
      selectedDates={selectedDates}
      accessibilityLabel="Loading orders analytics"
      subtitle="Orders over time"
      title="Orders"
    />
  );
};
