import { useQuery } from 'react-query';
import { PLANS_ENUM } from '~/components';
import { useSdk } from '~/hooks/useSdk';

export const useIsPaidPlan = () => {
  const sdk = useSdk();
  const { data: store } = useQuery(['currentStore'], () =>
    sdk.currentStore().then((res) => res.currentStore),
  );

  return (
    store?.tier?.billingVersion === 1 && store?.tier?.name !== PLANS_ENUM.BASIC
  );
};

export const useIsFreePlan = () => {
  const sdk = useSdk();
  const { data: store } = useQuery(['currentStore'], () =>
    sdk.currentStore().then((res) => res.currentStore),
  );

  return (
    store?.tier?.billingVersion === 1 && store?.tier?.name === PLANS_ENUM.BASIC
  );
};
