import { SubscriptionStatus } from '~/graphql/sdk';
import { useStore } from '~/hooks';
import LoadableV0 from './BillingV0/Loadable';
import LoadableV1 from './BillingV1/Loadable';

const Billing = () => {
  const store = useStore();
  const billingVersion = store?.tier?.billingVersion;
  const hasActiveSubscription =
    store?.subscriptionStatus === SubscriptionStatus.Active;

  if (typeof billingVersion !== 'number') return <div>Loading...</div>;

  return billingVersion === 0 && hasActiveSubscription ? (
    <LoadableV0 />
  ) : (
    <LoadableV1 />
  );
  // return <LoadableV1 />;
};

export default Billing;
