import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { ProductsTable } from './ProductsTable';

interface TopProductsProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const TopProducts = ({
  flowContainerId,
  selectedDates,
}: TopProductsProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsTopProducts', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsTopProducts({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsTopProducts),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <ProductsTable
      data={data}
      isLoading={isLoading}
      title="Top selling products"
      noResultsText="There were no products sold in this date range."
    />
  );
};
