import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, FormLayout, Form, Select, Stack } from '@shopify/polaris';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import rtlDetect from 'rtl-detect';
import * as Yup from 'yup';

import { ErrorBanner } from '~/components';
import config from '~/config';
import { Translation } from '~/graphql/sdk';
import { useSdk } from '~/hooks';
import { getErrorLength, useToast } from '~/lib';

const schema = Yup.object().shape({
  locale: Yup.string().required(),
});

interface LanguageModalProps {
  data?: Translation;
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  rename?: boolean;
}

export const LanguageModal = ({
  data,
  isOpen,
  setOpen,
  rename,
}: LanguageModalProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const [error, setError] = useState<any>();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({
    defaultValues: { locale: data?.locale || '' },
    resolver: yupResolver(schema),
  });

  const { data: existingTranslations } = useQuery(['translations'], () =>
    sdk.translations().then((res) => res.translations),
  );

  const onSubmit = async (input) => {
    try {
      if (rename) {
        if (!data?.id) {
          throw new Error();
        }

        const result = await sdk.updateOneTranslation({
          input: {
            id: data.id,
            update: input,
          },
        });

        reset({ locale: data?.locale || '' });
        queryClient.setQueryData(
          ['translation', { id: data.id }],
          result.updateOneTranslation,
        );
        toast({ content: 'Language renamed' });
        setOpen(false);
      } else {
        const { id } = (
          await sdk.createOneTranslation({
            input: {
              translation: {
                ...input,
                isRtl: rtlDetect.isRtlLang(input.locale),
              },
            },
          })
        ).createOneTranslation;

        queryClient.invalidateQueries(['translations']);
        toast({ content: 'Language added' });
        history.push(`/languages/${id}`);
      }
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      title={`${rename ? 'Rename' : 'Add'} language`}
      primaryAction={{
        content: rename ? 'Rename' : 'Add',
        onAction: handleSubmit(onSubmit),
        disabled: !isDirty,
        loading: isSubmitting,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setOpen(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Stack vertical>
            {!!error && (
              <ErrorBanner
                title={`To ${
                  rename ? 'rename' : 'add'
                } this language, ${getErrorLength(error)} ${
                  getErrorLength(error) > 1 ? 'changes need' : 'change needs'
                } to be made:`}
                error={error}
              />
            )}

            <FormLayout>
              <Controller
                control={control}
                name="locale"
                render={({ field: { ref, ...field } }) => (
                  <Select
                    label="Language"
                    labelHidden
                    options={[
                      { label: 'Select...', value: '' },
                      ...config.locales.filter(
                        ({ value }) =>
                          !existingTranslations?.edges.find(
                            ({ node: { locale } }) => value === locale,
                          ),
                      ),
                    ]}
                    error={errors?.locale?.message}
                    {...field}
                  />
                )}
              />
            </FormLayout>
          </Stack>
        </Form>
      </Modal.Section>
    </Modal>
  );
};
