import { useQuery } from 'react-query';

import { useSdk } from '~/hooks';

import { SessionsTable } from './SessionsTable';

interface SessionsPerCountryProps {
  flowContainerId: string;
  selectedDates: any;
}

export const SessionsPerCountry = ({
  flowContainerId,
  selectedDates,
}: SessionsPerCountryProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsSessionsPerCountry', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsSessionsPerCountry({
          input: { ...selectedDates, flowContainer: flowContainerId },
        })
        .then((res) => res.analyticsSessionsPerCountry),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <SessionsTable
      type="country"
      data={data}
      isLoading={isLoading}
      title="Sessions by location"
      noResultsText="There were no sessions in this date range."
    />
  );
};
