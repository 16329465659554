import { useCallback, useMemo } from 'react';
import { Tabs } from '@shopify/polaris';
import { useHistory, useLocation } from 'react-router';
import config from '~/config';
import { SubscriptionStatus } from '~/graphql/sdk';
import { useStore } from '~/hooks';

interface Tab {
  id: string;
  url: string;
  content: string;
}

const GENERAL_TABS: Tab[] = [
  {
    id: 'dashboard',
    url: '/',
    content: 'Dashboard',
  },
  {
    id: 'flows',
    url: '/flows',
    content: 'Flows',
  },
  {
    id: 'analytics',
    url: '/analytics',
    content: 'Analytics',
  },
  {
    id: 'integrations',
    url: '/integrations',
    content: 'Integrations',
  },
  {
    id: 'languages',
    url: '/languages',
    content: 'Languages',
  },
  {
    id: 'billing',
    url: '/billing',
    content: 'Billing',
  },
];

const FLOW_TABS: Tab[] = [
  {
    id: 'overview',
    url: '',
    content: 'Overview',
  },
  {
    id: 'analytics',
    url: '/analytics',
    content: 'Analytics',
  },
  {
    id: 'sessions',
    url: '/sessions',
    content: 'Sessions',
  },
  {
    id: 'connect',
    url: '/connect',
    content: 'Connect',
  },
  {
    id: 'settings',
    url: '/settings',
    content: 'Settings',
  },
];

const hidePaths = ['/flows/new'];

export const TabBar = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const store = useStore();

  const inFlow = /\/flows\/.+/i.test(pathname);

  const excludedTabIds: string[] = useMemo(() => {
    let excludedIds: string[] = [];
    const shouldShowBilling =
      config.subscriptionIsRequired ||
      store?.subscriptionStatus === SubscriptionStatus.Active;
    if (!shouldShowBilling) {
      excludedIds.push('billing');
    }

    return excludedIds;
  }, [store]);

  const tabs = useMemo(() => {
    let tabs: Tab[] = [];
    if (inFlow) {
      tabs = FLOW_TABS.map((tab) => ({
        ...tab,
        url: `/flows/${pathname.match(/\/flows\/(.*?)(\/.*)?$/i)?.[1]}${
          tab.url
        }`,
      }));
    } else {
      tabs = GENERAL_TABS;
    }

    return tabs.filter((tab) => !excludedTabIds.includes(tab.id));
  }, [inFlow, pathname]);

  const onSelectTab = useCallback(
    (selectedIdx) => {
      history.push(tabs[selectedIdx].url);
    },
    [history, tabs],
  );

  const selectedTab = useMemo(
    () =>
      tabs.findIndex(
        (tab) =>
          tab.url === pathname ||
          (tab.url === '/analytics' && pathname.startsWith('/analytics')) ||
          (tab.url === '/languages' && pathname.startsWith('/languages')),
      ),
    [pathname, tabs],
  );

  if (hidePaths.includes(pathname)) {
    return null;
  }

  return (
    <Tabs
      tabs={tabs}
      onSelect={onSelectTab}
      selected={selectedTab >= 0 ? selectedTab : -1}
    />
  );
};
