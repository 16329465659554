import { useMemo } from 'react';
import { Card, SkeletonBodyText, Stack, TextStyle } from '@shopify/polaris';
import Flags from 'country-flag-icons/react/3x2';
import { capitalize } from 'lodash';
import { Flag } from '..';

interface SessionsTableProps {
  data: any;
  isLoading: boolean;
  noResultsText?: string;
  title: string;
  type?: 'country';
}

export const SessionsTable = ({
  data,
  isLoading,
  noResultsText,
  title,
  type,
}: SessionsTableProps) => {
  const hasSessions = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    for (let i = 0; i < data.length; i += 1) {
      if (data[i]?.value?.find((v) => v.key === 'total')?.value) {
        return true;
      }
    }

    return false;
  }, [data]);

  return (
    <Card title={title}>
      <Card.Section>
        {isLoading || !data ? (
          <Stack vertical>
            <SkeletonBodyText lines={4} />
          </Stack>
        ) : hasSessions ? (
          <table style={{ width: '100%' }}>
            <tbody>
              {data.map(({ key, value }) => {
                const total = value?.find((v) => v.key === 'total')?.value;
                const converted = value?.find((v) => v.key === 'converted')
                  ?.value;

                return (
                  <tr key={key}>
                    <td
                      colSpan={2}
                      style={{ paddingBottom: '8px', verticalAlign: 'top' }}
                    >
                      <Stack spacing="tight">
                        {type === 'country' && Flags?.[key?.toUpperCase()] && (
                          <Flag countryCode={key?.toUpperCase()} />
                        )}
                        <Stack.Item>
                          {type === 'country' ? key : capitalize(key)}
                        </Stack.Item>
                      </Stack>
                    </td>
                    <td
                      colSpan={2}
                      style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                      <p>{total}</p>
                      <TextStyle variation="subdued">sessions</TextStyle>
                    </td>
                    <td
                      colSpan={2}
                      style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                      <p>
                        {converted}
                        {converted
                          ? ` (${((converted / total) * 100).toFixed(2)}%)`
                          : ''}
                      </p>
                      <TextStyle variation="subdued">converted</TextStyle>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <TextStyle variation="subdued">
            {noResultsText || 'No data found in this date range.'}
          </TextStyle>
        )}
      </Card.Section>
    </Card>
  );
};
