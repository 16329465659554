import { useQuery } from 'react-query';

import { useSdk } from '~/hooks';

import { SessionsTable } from './SessionsTable';

interface SessionsPerDeviceProps {
  flowContainerId: string;
  selectedDates: any;
}

export const SessionsPerDevice = ({
  flowContainerId,
  selectedDates,
}: SessionsPerDeviceProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsSessionsPerDevice', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsSessionsPerDevice({
          input: { ...selectedDates, flowContainer: flowContainerId },
        })
        .then((res) => res.analyticsSessionsPerDevice),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <SessionsTable
      data={data}
      isLoading={isLoading}
      title="Sessions by device"
      noResultsText="There were no sessions in this date range."
    />
  );
};
