import { FC, useState } from 'react';
import { DataTable, Card } from '@shopify/polaris';
import { useQuery } from 'react-query';
import { IAnalyticsComponent } from '~/components/AnalyticsCommon';
import EmptyTableMarkup from '~/components/EmptyTableMarkup';
import tableStyles from '~/components/Table/styles.module.scss';
import TableFooter from '~/components/Table/TableFooter';
import { SubmissionsQuery } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

const PAGE_SIZE = 10;

interface PageProps {
  after?: string;
  before?: string;
}

type TypeSubmissionInQuery = SubmissionsQuery['sessions']['edges'][0]['node']['selections'][0];

const SubmissionsTable: FC<IAnalyticsComponent> = ({
  flows,
  dateFilter,
  flowContainerId,
}) => {
  const sdk = useSdk();

  const [page, setPage] = useState<PageProps>({});

  const maxQuestionAmount = flows.reduce(
    (acc, flow) => Math.max(acc, flow.nodes.length),
    0,
  );

  const { isLoading: isTotalAmountLoading, data: sessionAmountData } = useQuery(
    ['sessionCount', { flowContainerId, dateFilter }],
    () => {
      return sdk
        .sessionCount({
          input: {
            startDate: dateFilter?.start,
            endDate: dateFilter?.end,
            ...(flowContainerId ? { flowContainerId } : {}),
            isCompleted: true,
          },
        })
        .then((res) => res.sessionCount);
    },
  );

  const { isLoading, data: sessionData } = useQuery(
    ['submissions', { page, flows, dateFilter }],
    () => {
      let paging: any = { ...page };

      if (page?.before) {
        paging.last = PAGE_SIZE;
      } else {
        paging.first = PAGE_SIZE;
      }

      return sdk
        .submissions({
          paging,
          filter: {
            ...(dateFilter
              ? {
                  createdAt: {
                    between: { lower: dateFilter.start, upper: dateFilter.end },
                  },
                }
              : {}),
            ...(flows.length
              ? { flowId: { in: flows?.map(({ id }) => id) } }
              : {}),
            completedAt: { isNot: null },
          },
        })
        .then((res) => res.sessions);
    },
  );

  const sessions = sessionData?.edges.map(({ node }) => node) || [];

  const emptyStateMarkup = (
    <Card.Section>
      <EmptyTableMarkup
        loadingOrEmpty={isLoading && !sessions?.length}
        accessibilityLabel="Loading submissions overview"
        title="No submissions found"
        description={`When customers use flows, their session submissions will show in this overview.`}
      />
    </Card.Section>
  );

  const rows = sessions.map((session) => {
    const aggregateSessionSelection = session?.selections?.reduce(
      (acc, selection) => {
        const lastQuestion = acc[acc.length - 1];

        if (lastQuestion?.[0]?.node?.id === selection?.node?.id) {
          lastQuestion.push(selection);
        } else {
          acc.push([selection]);
        }
        return acc;
      },
      [] as any,
    ) as TypeSubmissionInQuery[][];

    const questionAnswers = new Array(maxQuestionAmount)
      .fill('')
      .map((_, index) => {
        const selections = aggregateSessionSelection[index];
        if (!selections) return '-';

        const selectionsTitles = selections.map((selection) => {
          const optionId = selection.optionId;
          const title =
            selection?.node?.options?.find((option) => option.id === optionId)
              ?.label || '-';
          return <p key={selection.id}>{title}</p>;
        });

        return <div>{selectionsTitles}</div>;
      });

    const recommendations = session?.results?.length
      ? session?.results?.map((result) => (
          <p key={result.product.id}>{result.product.title}</p>
        ))
      : '-';

    return [
      session.email || '-',
      ...questionAnswers,
      <div>{recommendations}</div>,
    ];
  });

  const questionTitles = new Array(maxQuestionAmount)
    .fill(null)
    .map((_, index) => `Question ${index + 1}`);

  if (!flows.length) {
    return <Card>{emptyStateMarkup}</Card>;
  }

  return (
    <Card>
      <div className={tableStyles.commonDataTable}>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            ...new Array(maxQuestionAmount).fill('text'),
          ]}
          headings={['Email/Phone', ...questionTitles, 'Recommendations']}
          rows={rows.length ? rows : [[emptyStateMarkup]]}
          footerContent={
            <TableFooter
              hasPrevious={!!sessionData?.pageInfo?.hasPreviousPage}
              hasNext={!!sessionData?.pageInfo?.hasNextPage}
              onPrevious={() => {
                setPage({ before: sessionData?.pageInfo?.startCursor });
              }}
              onNext={() => {
                setPage({
                  after: sessionData?.pageInfo?.endCursor,
                });
              }}
              totalAmount={sessionAmountData?.sessionAmount}
              currentAmount={rows.length}
              isLoading={isLoading || isTotalAmountLoading}
            />
          }
        />
      </div>
    </Card>
  );
};

export default SubmissionsTable;
