import { lazy, Suspense } from 'react';
import {
  Page,
  Layout,
  Card,
  Heading,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextContainer,
} from '@shopify/polaris';
import { range } from 'lodash';

import { LazyLoad } from '~/components';

const Component = lazy(() => import('~/containers/Home'));

export default () => (
  <Suspense
    fallback={
      <LazyLoad>
        <Page>
          <Layout>
            <Layout.Section>
              <Heading>Recent performance</Heading>
            </Layout.Section>

            <Layout.Section fullWidth>
              <Layout>
                {range(3).map((idx) => (
                  <Layout.Section oneThird key={idx}>
                    <Card sectioned>
                      <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="large" />
                      </TextContainer>
                    </Card>
                  </Layout.Section>
                ))}
              </Layout>
            </Layout.Section>

            <Layout.Section>
              <Heading>Learn</Heading>
            </Layout.Section>

            {range(2).map((idx) => (
              <Layout.Section key={idx}>
                <Card sectioned>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                </Card>
              </Layout.Section>
            ))}
          </Layout>
        </Page>
      </LazyLoad>
    }
  >
    <Component />
  </Suspense>
);
