import React from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { Modal } from '@shopify/polaris';
import {
  EmailNewsletterMajor,
  ExchangeMajor,
  PaintBrushMajor,
  CirclePlusMajor,
  CircleTickMajor,
  SmileyJoyMajor,
} from '@shopify/polaris-icons';
import clx from 'classnames';
import { useHistory } from 'react-router';
import DescriptionCard from '~/components/BillingModal/DescriptionCard';
import styles from './styles.module.scss';

interface BillingModalProps {
  open: boolean;
  setClose: () => void;
}

const BillingModal: React.FC<BillingModalProps> = ({ open, setClose }) => {
  const history = useHistory();
  const app = useAppBridge();

  return (
    <Modal
      open={open}
      onClose={setClose}
      title={'Unlock Email, CSS, Logic Jumps, and More! [Starting at $19]'}
      primaryAction={{
        content: 'Start your 14 days free trial',
        onAction() {
          history.push(`/billing`);
          setClose();
        },
      }}
      secondaryActions={[
        {
          content: 'Learn more',
          onAction() {
            //todo: add redirect to learn more about plans

            app.dispatch(
              Redirect.toRemote({
                url: 'https://productfinder.app/pricing',
                newContext: true,
              }),
            );
          },
        },
      ]}
    >
      <div className={styles.modalWrapper}>
        <Modal.Section>
          <div className={clx(styles.cardsLine, styles.cardsLine__purpleIcon)}>
            <DescriptionCard
              icon={EmailNewsletterMajor}
              title={'Email Results'}
              content={'Segment customers and send results to them via email.'}
            />
            <DescriptionCard
              icon={PaintBrushMajor}
              title={'CSS Editing'}
              content={
                'Out team can help you customize your quiz flow to better fit your site.'
              }
            />
          </div>
          <div className={clx(styles.cardsLine, styles.cardsLine__purpleIcon)}>
            <DescriptionCard
              icon={ExchangeMajor}
              title={'Logic Jumps'}
              content={
                'Use conditional logic to create more complex quiz flows.'
              }
            />
            <DescriptionCard
              icon={CirclePlusMajor}
              title={'And Much More..!'}
              content={
                'Im-page embed types, integrate reviews, multi-choice questions, and more.'
              }
            />
          </div>
          <div className={clx(styles.cardsLine, styles.cardsLine__greenIcon)}>
            <DescriptionCard
              icon={CircleTickMajor}
              title={'500% ROI Positive Guarantee'}
            />
            <DescriptionCard
              icon={SmileyJoyMajor}
              title={'1000+ Happy Customers'}
            />
          </div>
          <img
            src={'/images/ppf-app-features.png'}
            alt={'PPF app'}
            className={styles.img}
          />
        </Modal.Section>
      </div>
    </Modal>
  );
};

export default BillingModal;
