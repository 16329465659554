import { lazy, Suspense } from 'react';
import { SkeletonPage, Card, SkeletonBodyText } from '@shopify/polaris';

import { LazyLoad } from '~/components';

const Component = lazy(() => import('~/containers/LanguagesOverview'));

export default (props) => (
  <Suspense
    fallback={
      <LazyLoad>
        <SkeletonPage title="Languages" primaryAction>
          <Card sectioned>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
        </SkeletonPage>
      </LazyLoad>
    }
  >
    <Component {...props} />
  </Suspense>
);
