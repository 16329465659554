import { FC, useMemo, useState } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import {
  Badge,
  Card,
  ChoiceList,
  Filters,
  IndexTable,
  Link,
  Pagination,
  Select,
  Stack,
  TextStyle,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { Flag } from '~/components';
import { IAnalyticsComponent } from '~/components/AnalyticsCommon';
import EmptyTableMarkup from '~/components/EmptyTableMarkup';
import SessionStatusBadge from '~/components/Table/SessionStatusBadge';
import tableStyles from '~/components/Table/styles.module.scss';
import { SessionSortFields, SessionStatus, SortDirection } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

const resourceName = {
  singular: 'session',
  plural: 'sessions',
};

const PAGE_SIZE = 50;

const STATUS_FILTERS = [{ label: 'Converted', value: SessionStatus.Converted }];

const sortOptions = [
  {
    label: 'Date (newest first)',
    value: 'CREATED_AT_DESC',
  },
  {
    label: 'Date (oldest first)',
    value: 'CREATED_AT_ASC',
  },
];

interface PageProps {
  after?: string;
  before?: string;
}

const SessionsTable: FC<IAnalyticsComponent> = ({
  flows,
  isAll = false,
  dateFilter,
}) => {
  const app = useAppBridge();
  const sdk = useSdk();

  const [statusFilter, setStatusFilter] = useState<any[]>([]);
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('CREATED_AT_DESC');
  const [page, setPage] = useState<PageProps>({});

  const { isFetching, isLoading, data: sessionData } = useQuery(
    [
      'sessions',
      { page, queryValue, sortValue, statusFilter, flows, isAll, dateFilter },
    ],
    () => {
      let paging: any = { ...page };

      if (page?.before) {
        paging.last = PAGE_SIZE;
      } else {
        paging.first = PAGE_SIZE;
      }

      return sdk
        .sessions({
          paging,
          filter: {
            ...(dateFilter
              ? {
                  createdAt: {
                    between: { lower: dateFilter.start, upper: dateFilter.end },
                  },
                }
              : {}),
            ...(isAll ? {} : { flowId: { in: flows?.map(({ id }) => id) } }),
            ...(!queryValue && statusFilter?.length
              ? { orderName: { neq: '' } }
              : {}),
            ...(queryValue ? { orderName: { iLike: `%${queryValue}%` } } : {}),
          },
          sorting: [
            {
              field: SessionSortFields.CreatedAt,
              direction:
                sortValue === 'CREATED_AT_ASC'
                  ? SortDirection.Asc
                  : SortDirection.Desc,
            },
          ],
        })
        .then((res) => res.sessions);
    },
  );

  const sessions = sessionData?.edges.map(({ node }) => node) || [];

  const hasMoreItems = useMemo(
    () =>
      !!sessionData?.pageInfo?.hasNextPage ||
      !!sessionData?.pageInfo?.hasPreviousPage,
    [sessionData],
  );

  const { selectedResources } = useIndexResourceState(sessions);

  const filters = [
    {
      key: 'statusFilter',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={STATUS_FILTERS}
          selected={statusFilter || []}
          onChange={(v) => {
            setStatusFilter(v);
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = statusFilter.length
    ? [
        {
          key: 'statusFilter',
          label: `${statusFilter
            .map((id) => STATUS_FILTERS.find((s) => s.value === id)?.label)
            .join(', ')}`,
          onRemove: () => {
            setStatusFilter([]);
          },
        },
      ]
    : [];

  const emptyStateMarkup = (
    <EmptyTableMarkup
      loadingOrEmpty={isLoading && !sessions?.length}
      accessibilityLabel="Loading sessions overview"
      title="No sessions found"
      description={`When customers use ${
        flows.length > 1 ? 'flows' : 'this flow'
      }, their session will show in this overview.`}
    />
  );

  const rowMarkup = sessions.map(
    (
      {
        id,
        status,
        country,
        createdAt,
        isTest,
        orderId,
        orderName,
        email,
        originalSessionId,
        containerName,
      },
      index,
    ) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <Stack>
            {country ? (
              <Stack spacing="tight">
                <Stack.Item>
                  <Flag countryCode={country} />
                </Stack.Item>
                <Stack.Item>{country}</Stack.Item>
              </Stack>
            ) : (
              <TextStyle variation="subdued">Unknown</TextStyle>
            )}
            {isTest && (
              <Tooltip content="This session was created through preview mode, and will be excluded from analytics.">
                <Badge status="info" size="medium">
                  Test
                </Badge>
              </Tooltip>
            )}
            {!!originalSessionId && <Badge size="medium">Restart</Badge>}
          </Stack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {format(new Date(createdAt), "PP 'at' p")}
        </IndexTable.Cell>
        {!flows.length && <IndexTable.Cell>{containerName}</IndexTable.Cell>}
        <IndexTable.Cell>
          {email || <TextStyle variation="subdued">–</TextStyle>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SessionStatusBadge status={status} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {orderId ? (
            <Link
              onClick={() => {
                app.dispatch(
                  Redirect.toAdminSection({
                    section: {
                      name: Redirect.ResourceType.Order,
                      resource: {
                        id: orderId,
                      },
                    },
                  }),
                );
              }}
            >
              {orderName}
            </Link>
          ) : (
            <TextStyle variation="subdued">–</TextStyle>
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <>
      <Card>
        <div style={{ padding: '16px', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={(v) => {
                setQueryValue(v);
              }}
              onQueryClear={() => {
                setQueryValue('');
              }}
              onClearAll={() => {
                setQueryValue('');
                setStatusFilter([]);
              }}
            />
          </div>

          <div style={{ paddingLeft: '0.8rem' }}>
            <Select
              labelInline
              label="Sort by"
              options={sortOptions}
              value={sortValue}
              onChange={setSortValue}
            />
          </div>
        </div>

        <div className={tableStyles.commonIndexTable}>
          <IndexTable
            resourceName={resourceName}
            itemCount={sessions.length}
            loading={isFetching}
            emptyState={emptyStateMarkup}
            selectedItemsCount={0}
            selectable={false}
            onSelectionChange={() => {}}
            headings={[
              { title: 'Location' },
              { title: 'Date' },
              ...(flows.length ? [] : [{ title: 'Quiz name' }]),
              { title: 'Email' },
              { title: 'Status' },
              { title: 'Order' },
            ]}
          >
            {rowMarkup}
          </IndexTable>
        </div>

        {hasMoreItems && (
          <div
            style={{
              paddingLeft: '16px',
              paddingBottom: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              hasPrevious={!!sessionData?.pageInfo?.hasPreviousPage}
              onPrevious={() => {
                setPage({ before: sessionData?.pageInfo?.startCursor });
              }}
              hasNext={!!sessionData?.pageInfo?.hasNextPage}
              onNext={() => {
                setPage({
                  after: sessionData?.pageInfo?.endCursor,
                });
              }}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default SessionsTable;
