import {
  Card,
  Icon,
  SkeletonBodyText,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';

interface ProductsTableProps {
  data: any;
  isLoading: boolean;
  noResultsText?: string;
  title: string;
  type?: 'recommended';
}

export const ProductsTable = ({
  data,
  isLoading,
  noResultsText,
  title,
  type,
}: ProductsTableProps) => {
  return (
    <Card title={title}>
      <Card.Section>
        {isLoading || !data ? (
          <Stack vertical>
            <SkeletonBodyText lines={4} />
          </Stack>
        ) : data.length ? (
          <table style={{ width: '100%' }}>
            <tbody>
              {data
                .slice(0, 10)
                .map(
                  ({ productId, productName, changePercentage, ...item }) => (
                    <tr key={productId}>
                      <td
                        colSpan={2}
                        style={{ paddingBottom: '8px', maxWidth: '50%' }}
                      >
                        <p>{productName}</p>
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: 'right',
                          verticalAlign: 'top',
                          padding: '0 2rem',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {type === 'recommended'
                          ? `${item.recommendationCount} (${Math.round(
                              item.recommendationPercentage,
                            )}%)`
                          : item.count}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: 'right',
                          verticalAlign: 'top',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Stack
                          alignment="center"
                          spacing="extraTight"
                          distribution="trailing"
                          wrap={false}
                        >
                          {changePercentage !== 0 &&
                            changePercentage !== null &&
                            changePercentage !== undefined && (
                              <Icon
                                color={
                                  changePercentage > 0 ? 'success' : 'critical'
                                }
                                source={
                                  changePercentage > 0
                                    ? ArrowUpMinor
                                    : ArrowDownMinor
                                }
                              />
                            )}
                          <>
                            {changePercentage === 0 ||
                            changePercentage === null ||
                            changePercentage === undefined
                              ? '–'
                              : `${Math.round(changePercentage)}%`}
                          </>
                        </Stack>
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </table>
        ) : (
          <TextStyle variation="subdued">
            {noResultsText || 'No data found in this date range.'}
          </TextStyle>
        )}
      </Card.Section>
    </Card>
  );
};
