import React from 'react';
import { Card, Heading, Icon, IconSource } from '@shopify/polaris';
import styles from './styles.module.scss';

interface DescriptionCardProps {
  icon: IconSource;
  title: string;
  content?: string;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <Card
      title={
        <div className={styles.cardTitle}>
          <Icon source={icon} />
          <Heading>{title}</Heading>
        </div>
      }
    >
      {!!content && <Card.Section>{content}</Card.Section>}
    </Card>
  );
};

export default DescriptionCard;
