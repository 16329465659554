import React from 'react';
import {
  Card,
  CardProps,
  ComplexAction,
  List,
  TextStyle,
} from '@shopify/polaris';
import styles from './styles.module.scss';

interface PlanCardProps {
  title: React.ReactNode;
  button: ComplexAction & { isPrimary: boolean };
  benefits: string[];
  price: string;
  pricePer: string;
  description: React.ReactNode;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  button,
  benefits,
  price,
  pricePer,
  description,
}) => {
  const cardActions: CardProps = {};
  if (button.isPrimary) {
    cardActions.primaryFooterAction = button;
  } else {
    cardActions.secondaryFooterActions = [button];
  }

  return (
    <div className={styles.cardContainer}>
      <Card title={title} {...cardActions} footerActionAlignment="left">
        <Card.Section>
          <p>
            <span className={styles.price}>
              {price} {'  '}
            </span>
            <TextStyle variation={'subdued'}>{pricePer}</TextStyle>
          </p>
          <div>{description}</div>
        </Card.Section>
        <Card.Section flush={false}>
          <List>
            {benefits.map((item, index) => (
              <List.Item key={index}>{item}</List.Item>
            ))}
          </List>
        </Card.Section>
      </Card>
    </div>
  );
};

export default PlanCard;
