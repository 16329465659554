import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Chart } from './Chart';

interface SessionsProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const Sessions = ({ flowContainerId, selectedDates }: SessionsProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsSessions', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsSessions({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsSessions),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Chart
      data={data}
      isLoading={isLoading}
      selectedDates={selectedDates}
      subtitle="Engagements over time"
      title="Engagements"
      accessibilityLabel="Loading engagements analytics"
    />
  );
};
