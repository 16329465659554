import { useState } from 'react';
import { Layout } from '@shopify/polaris';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { DateRange, DateRangePicker } from '~/components';

import { AverageOrderValue } from './AverageOrderValue';
import { CollectedEmails } from './CollectedEmails';
import { Conversions } from './Conversions';
import { Orders } from './Orders';
import { Sales } from './Sales';
import { Sessions } from './Sessions';
import { SessionsPerCountry } from './SessionsPerCountry';
import { SessionsPerDevice } from './SessionsPerDevice';
import { TopProducts } from './TopProducts';
import { TopRecommended } from './TopRecommended';

interface AnalyticsProps {
  flowContainerId?: string;
  isDatePicker?: boolean;
  dateFilter?: DateRange;
}

export const Analytics = ({
  flowContainerId,
  isDatePicker = true,
  dateFilter,
}: AnalyticsProps) => {
  const [localSelectedDates, setSelectedDates] = useState({
    start: startOfDay(subDays(new Date(), 6)),
    end: endOfDay(new Date()),
  });

  const selectedDates = isDatePicker ? localSelectedDates : dateFilter;

  return (
    <Layout>
      {!!isDatePicker && (
        <Layout.Section fullWidth>
          <DateRangePicker
            selectedDates={localSelectedDates}
            setSelectedDates={setSelectedDates}
          />
        </Layout.Section>
      )}

      <Layout.Section oneThird>
        <Sales
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        <AverageOrderValue
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        <TopProducts
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
      </Layout.Section>

      <Layout.Section oneThird>
        <Sessions
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        <Orders
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        <TopRecommended
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
      </Layout.Section>

      <Layout.Section oneThird>
        <Conversions
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        <CollectedEmails
          flowContainerId={flowContainerId}
          selectedDates={selectedDates}
        />
        {!!flowContainerId && (
          <SessionsPerCountry
            flowContainerId={flowContainerId}
            selectedDates={selectedDates}
          />
        )}
        {!!flowContainerId && (
          <SessionsPerDevice
            flowContainerId={flowContainerId}
            selectedDates={selectedDates}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};
