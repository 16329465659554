export default {
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  apiUrl: process.env.REACT_APP_API_URL || '',
  graphqlUrl:
    process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:3001/graphql',
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID || '',
  subscriptionIsRequired:
    process.env.REACT_APP_SUBSCRIPTION_REQUIRED === 'false' ? false : true,
  seo: {
    defaultTitle: 'Perfect Product Finder',
    meta: [
      {
        name: 'description',
        content: 'Perfect Product Finder. Shopify app by Beam Commerce.',
      },
    ],
  },
  locales: [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Akan', value: 'ak' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Assamese', value: 'as' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bambara', value: 'bm' },
    { label: 'Bangla', value: 'bn' },
    { label: 'Basque', value: 'eu' },
    { label: 'Belarusian', value: 'be' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Breton', value: 'br' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Burmese', value: 'my' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Chechen', value: 'ce' },
    { label: 'Chinese (Simplified)', value: 'zh-CN' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Church Slavic', value: 'cu' },
    { label: 'Cornish', value: 'kw' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dutch', value: 'nl' },
    { label: 'Dzongkha', value: 'dz' },
    { label: 'English', value: 'en' },
    { label: 'Esperanto', value: 'eo' },
    { label: 'Estonian', value: 'et' },
    { label: 'Ewe', value: 'ee' },
    { label: 'Faroese', value: 'fo' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'Fulah', value: 'ff' },
    { label: 'Galician', value: 'gl' },
    { label: 'Ganda', value: 'lg' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Igbo', value: 'ig' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Interlingua', value: 'ia' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Javanese', value: 'jv' },
    { label: 'Kalaallisut', value: 'kl' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kashmiri', value: 'ks' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Khmer', value: 'km' },
    { label: 'Kikuyu', value: 'ki' },
    { label: 'Kinyarwanda', value: 'rw' },
    { label: 'Korean', value: 'ko' },
    { label: 'Kurdish', value: 'ku' },
    { label: 'Kyrgyz', value: 'ky' },
    { label: 'Lao', value: 'lo' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lingala', value: 'ln' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Luba-Katanga', value: 'lu' },
    { label: 'Luxembourgish', value: 'lb' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Malagasy', value: 'mg' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Manx', value: 'gv' },
    { label: 'Maori', value: 'mi' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Nepali', value: 'ne' },
    { label: 'North Ndebele', value: 'nd' },
    { label: 'Northern Sami', value: 'se' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Norwegian (Bokmål)', value: 'nb' },
    { label: 'Norwegian Nynorsk', value: 'nn' },
    { label: 'Odia', value: 'or' },
    { label: 'Oromo', value: 'om' },
    { label: 'Ossetic', value: 'os' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Persian', value: 'fa' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Brazil)', value: 'pt-BR' },
    { label: 'Portuguese (Portugal)', value: 'pt-PT' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Quechua', value: 'qu' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Romansh', value: 'rm' },
    { label: 'Rundi', value: 'rn' },
    { label: 'Russian', value: 'ru' },
    { label: 'Sango', value: 'sg' },
    { label: 'Scottish Gaelic', value: 'gd' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Shona', value: 'sn' },
    { label: 'Sichuan Yi', value: 'ii' },
    { label: 'Sindhi', value: 'sd' },
    { label: 'Sinhala', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Sundanese', value: 'su' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tajik', value: 'tg' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Tatar', value: 'tt' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Tibetan', value: 'bo' },
    { label: 'Tigrinya', value: 'ti' },
    { label: 'Tongan', value: 'to' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Turkmen', value: 'tk' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uyghur', value: 'ug' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Volapük', value: 'vo' },
    { label: 'Welsh', value: 'cy' },
    { label: 'Western Frisian', value: 'fy' },
    { label: 'Wolof', value: 'wo' },
    { label: 'Xhosa', value: 'xh' },
    { label: 'Yiddish', value: 'yi' },
    { label: 'Yoruba', value: 'yo' },
    { label: 'Zulu', value: 'zu' },
  ],
};
