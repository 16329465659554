import React, { useState } from "react";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { Badge, DisplayText, Icon, Modal } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import { useMutation, useQuery } from "react-query";

import { CreateSubscriptionMutationVariables, Tier } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { useToast } from "~/lib";
import PlanCard from "./PlanCard";
import styles from "./styles.module.scss";

const benefitList = {
  growth: [
    "Every feature in the free plan",
    "Email sending (in-app emails, Klaviyo, Mailchimp)",
    "CSS design personalization",
    "Advanced logic jumps",
    "Custom quiz placement (in-page)",
    "Review integrations",
    "Advanced question types (multi-choice)",
    "Export quiz answers",
  ],
  basic: [
    "Unlimited quizzes",
    "Basic question types (single choice)",
    "Revenue tracking",
    "Result matching",
    "Export leads",
    "Translations",
  ],
};

interface PricingPlansProps {
  currentPlan?: { __typename?: "Tier" } & Pick<
    Tier,
    "id" | "maxEngagements" | "name" | "price" | "billingVersion"
  >;
}

export enum PLANS_ENUM {
  "BASIC" = "Basic",
  "GROWTH" = "Growth",
}

const PricingPlans: React.FC<PricingPlansProps> = ({ currentPlan }) => {
  const app = useAppBridge();
  const sdk = useSdk();
  const toast = useToast();
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isBasic =
    currentPlan?.billingVersion === 1 && currentPlan?.name === PLANS_ENUM.BASIC;
  const isGrowth =
    currentPlan?.billingVersion === 1 &&
    currentPlan?.name === PLANS_ENUM.GROWTH;

  const { data, isLoading: tiersLoading } = useQuery(
    ["tiers"],
    () =>
      sdk
        .tiers({
          filter: {
            billingVersion: {
              eq: 1,
            },
            isPublic: {
              is: false,
            },
          },
        })
        .then((res) => res.tiers),
    { refetchOnWindowFocus: false, suspense: true }
  );

  const tiers = data?.edges.map(({ node }) => node);

  const { mutateAsync } = useMutation(
    ["tiers"],
    (variables: CreateSubscriptionMutationVariables) =>
      sdk
        .createSubscription(variables)
        .then((res) => res.shopifyCreateSubscription)
  );

  const createSubscription = async (tierId?: string) => {
    try {
      if (!tierId) {
        throw new Error();
      }

      setSubscriptionLoading(true);

      const returnUrl = await mutateAsync(
        { input: { tierId } },
        {
          onSettled: () => setSubscriptionLoading(false),
          onError: () => {
            throw new Error();
          },
        }
      );

      if (returnUrl) {
        const isSamePage = returnUrl.endsWith(location.pathname);

        isSamePage
          ? window.location.reload()
          : app.dispatch(
              Redirect.toRemote({
                url: returnUrl,
              })
            );
      }
    } catch (err) {
      toast({
        content: "Error updating subscription, please contact support",
        error: true,
      });
    }
  };

  const loading = tiersLoading || subscriptionLoading;

  return (
    <div className={styles.plansWrapper}>
      <Modal
        title="Accept free plan"
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        primaryAction={{
          content: "Accept",
          loading: loading,
          onAction: async () => {
            const basicTier = tiers?.find(
              (tier) => tier.name === PLANS_ENUM.BASIC
            );
            await createSubscription(basicTier?.id);
            setIsModalOpen(false);
          },
        }}
        secondaryActions={[
          {
            content: "Close",
            loading: loading,
            onAction() {
              setIsModalOpen(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <DisplayText size={"small"}>
            Are you sure that you want to activate the Basic plan?
          </DisplayText>
        </Modal.Section>
      </Modal>
      <PlanCard
        title={
          <h2 className={"Polaris-Heading"}>
            Grows <Badge status="info">Most Popular</Badge>{" "}
          </h2>
        }
        button={{
          content: isGrowth ? "Current" : "START 14-DAY FREE TRIAL",
          onAction: async () => {
            const growthTier = tiers?.find(
              (tier) => tier.name === PLANS_ENUM.GROWTH
            );
            await createSubscription(growthTier?.id);
          },
          disabled: isGrowth,
          isPrimary: !isGrowth,
          loading: loading,
        }}
        benefits={benefitList.growth}
        price="$19"
        pricePer="per month (starting)"
        description={
          <>
            <p>100 quiz completions per month</p>
            <a className={styles.priceLink}>
              See pricing table for higher usage{" "}
              <Icon source={ChevronDownMinor} />
            </a>
          </>
        }
      />
      <PlanCard
        title="Basic"
        button={{
          content: isBasic ? "Current" : "SELECT PLAN",
          onAction() {
            setIsModalOpen(true);
          },
          disabled: isBasic,
          isPrimary: !isBasic,
          loading: loading,
        }}
        benefits={benefitList.basic}
        price="Free"
        pricePer="per month"
        description="Unlimited quiz completions per month"
      />
    </div>
  );
};

export default PricingPlans;
