import React from 'react';
import { EmptySearchResult, Spinner } from '@shopify/polaris';

export interface EmptyTableMarkupProps {
  loadingOrEmpty: boolean;
  accessibilityLabel: string;
  title: string;
  description: string;
}

const EmptyTableMarkup: React.FC<EmptyTableMarkupProps> = ({
  loadingOrEmpty,
  accessibilityLabel,
  title,
  description,
}) => {
  return loadingOrEmpty ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '200px',
      }}
    >
      <Spinner
        accessibilityLabel={accessibilityLabel || 'Loading'}
        size="large"
      />
    </div>
  ) : (
    <EmptySearchResult
      title={title || 'No entities found'}
      description={description || ''}
      withIllustration
    />
  );
};

export default EmptyTableMarkup;
