import { FC } from 'react';
import { Pagination, SkeletonDisplayText } from '@shopify/polaris';
import styles from './styles.module.scss';

interface ITableFooterProps {
  hasPrevious: boolean;
  hasNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  totalAmount?: number;
  currentAmount: number;
  isLoading: boolean;
}

const TableFooter: FC<ITableFooterProps> = ({
  totalAmount,
  currentAmount,
  isLoading,
  ...paginationProps
}) => {
  const moreThanOnePage =
    paginationProps.hasNext || paginationProps.hasPrevious;

  if (!isLoading && !totalAmount) {
    return null;
  }

  return (
    <div className={styles.tableFooter}>
      {moreThanOnePage && <Pagination {...paginationProps} />}
      {isLoading || !totalAmount ? (
        <SkeletonDisplayText size={'small'} />
      ) : (
        <p>{`Showing ${currentAmount} of ${totalAmount} result${
          totalAmount === 1 ? '' : 's'
        }`}</p>
      )}
    </div>
  );
};

export default TableFooter;
