import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  Popover,
  TextField,
  TextStyle,
  Stack,
  Select,
} from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import {
  differenceInDays,
  endOfDay,
  format,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  startOfDay,
  subDays,
} from 'date-fns';
import { useToast } from '~/lib';

const dateRanges = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last7Days' },
];

export type DateRange = {
  start: Date;
  end: Date;
};

interface DateRangePickerProps {
  selectedDates: DateRange;
  setSelectedDates(value: DateRange): void;
  compared?: boolean;
}

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

export const formatDateRange = (start: Date, end: Date) => {
  if (isSameDay(start, end)) {
    if (isSameDay(start, new Date())) {
      return 'Today';
    }

    return format(
      start,
      isSameYear(start, new Date()) ? 'd MMM' : 'd MMM yyyy',
    );
  }

  return `${format(
    start,
    `d${isSameMonth(start, end) ? '' : ' MMM'}${
      isSameYear(start, end) ? '' : ' yyyy'
    }`,
  )}–${format(end, isSameYear(start, new Date()) ? 'd MMM' : 'd MMM yyyy')}`;
};

export const usePreviousDateRange = (dateRange: DateRange) => {
  const previousDateRange = useMemo(() => {
    const end = subDays(dateRange.start, 1);
    const start = subDays(
      end,
      differenceInDays(dateRange.end, dateRange.start),
    );

    return formatDateRange(start, end);
  }, [dateRange]);

  return previousDateRange;
};

export const DateRangePicker = ({
  selectedDates: currentSelectedDates,
  setSelectedDates: setCurrentSelectedDates,
  compared = true,
}: DateRangePickerProps) => {
  const toast = useToast();

  const [popoverActive, setPopoverActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: getMonth(currentSelectedDates.start),
    year: getYear(currentSelectedDates.start),
  });
  const [selectedDates, setSelectedDatesState] = useState(currentSelectedDates);
  const [startDateText, setStartDateText] = useState(
    formatDate(currentSelectedDates.start),
  );
  const [endDateText, setEndDateText] = useState(
    formatDate(currentSelectedDates.end),
  );
  const [presetValue, setPresetValue] = useState(dateRanges[0].value);
  const [showCustomPreset, setShowCustomPreset] = useState(false);

  const setSelectedDates = (value) => {
    setSelectedDatesState(value);
    setShowCustomPreset(true);
    setPresetValue('custom');
  };

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const apply = () => {
    if (differenceInDays(selectedDates.end, selectedDates.start) > 366) {
      toast({ error: true, content: 'Date range can be at most 1 year' });
      return;
    }

    setCurrentSelectedDates({
      start: startOfDay(selectedDates.start),
      end: endOfDay(selectedDates.end),
    });
    togglePopoverActive();
  };

  const resetState = () => {
    setSelectedDates(currentSelectedDates);
    setDate({
      month: getMonth(currentSelectedDates.start),
      year: getYear(currentSelectedDates.start),
    });
    setStartDateText(formatDate(currentSelectedDates.start));
    setEndDateText(formatDate(currentSelectedDates.end));
  };

  useEffect(() => {
    resetState();
  }, [currentSelectedDates]);

  const currentRange = useMemo(
    () => formatDateRange(currentSelectedDates.start, currentSelectedDates.end),
    [currentSelectedDates],
  );

  const comparedTo = usePreviousDateRange(currentSelectedDates);

  return (
    <Stack alignment="center" spacing="tight">
      <Popover
        active={popoverActive}
        onClose={togglePopoverActive}
        activator={
          <Button icon={CalendarMinor} onClick={togglePopoverActive}>
            {currentRange}
          </Button>
        }
        fluidContent
        preferredAlignment="left"
      >
        <Popover.Pane>
          <Popover.Section>
            <Stack vertical>
              <Select
                label="Date range"
                options={[
                  ...(showCustomPreset
                    ? [{ label: 'Custom', value: 'custom' }]
                    : []),
                  ...dateRanges,
                ]}
                onChange={(value) => {
                  if (value !== 'custom') {
                    let newValue;

                    switch (value) {
                      case 'today':
                        newValue = {
                          start: new Date(),
                          end: new Date(),
                        };
                        break;
                      case 'yesterday':
                        newValue = {
                          start: subDays(new Date(), 1),
                          end: subDays(new Date(), 1),
                        };
                        break;
                      case 'last7Days':
                        newValue = {
                          start: subDays(new Date(), 6),
                          end: new Date(),
                        };
                        break;
                      default:
                    }

                    if (newValue) {
                      setSelectedDates(newValue);
                      setStartDateText(formatDate(newValue.start));
                      setEndDateText(formatDate(newValue.end));

                      setPresetValue(value);
                      setShowCustomPreset(false);
                    }
                  }
                }}
                value={presetValue}
              />

              <Stack distribution="fillEvenly">
                <TextField
                  label="Starting"
                  autoComplete="off"
                  onChange={(value) => {
                    setStartDateText(value);

                    const start = new Date(value);

                    if (isValid(start)) {
                      const isAfterEnd = isAfter(start, selectedDates.end);

                      setSelectedDates({
                        start,
                        end: isAfterEnd ? start : selectedDates.end,
                      });

                      if (isAfterEnd) {
                        setEndDateText(value);
                      }
                    }
                  }}
                  value={startDateText}
                />
                <TextField
                  label="Ending"
                  autoComplete="off"
                  onChange={(value) => {
                    setEndDateText(value);

                    const end = new Date(value);

                    if (isValid(end)) {
                      const isBeforeStart = isBefore(end, selectedDates.start);

                      setSelectedDates({
                        end,
                        start: isBeforeStart ? end : selectedDates.start,
                      });

                      if (isBeforeStart) {
                        setStartDateText(value);
                      }
                    }
                  }}
                  value={endDateText}
                />
              </Stack>

              <div style={{ maxWidth: '48rem' }}>
                <DatePicker
                  month={month}
                  year={year}
                  onChange={(value) => {
                    setSelectedDates(value);
                    setStartDateText(formatDate(value.start));
                    setEndDateText(formatDate(value.end));
                  }}
                  onMonthChange={handleMonthChange}
                  selected={selectedDates}
                  multiMonth
                  allowRange
                  disableDatesAfter={new Date()}
                />
              </div>
            </Stack>
          </Popover.Section>
        </Popover.Pane>

        <Popover.Pane fixed>
          <Popover.Section>
            <Stack distribution="equalSpacing">
              <Button
                onClick={() => {
                  togglePopoverActive();
                  resetState();
                }}
              >
                Cancel
              </Button>
              <Button primary onClick={apply}>
                Apply
              </Button>
            </Stack>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
      {compared && (
        <TextStyle variation="subdued">compared to {comparedTo}</TextStyle>
      )}
    </Stack>
  );
};
