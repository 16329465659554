import {
  Card,
  Icon,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  Subheading,
  TextStyle,
} from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';
import { round } from 'lodash';
import { useQuery } from 'react-query';

import { NumberComparison } from '~/components';
import { useSdk } from '~/hooks';

const funnelSteps = [
  { step: 'sessions', label: 'Views' },
  { step: 'engagements', label: 'Engagements' },
  { step: 'reachedResults', label: 'Reached results' },
  { step: 'converted', label: 'Sessions converted' },
];

interface ConversionsProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const Conversions = ({
  flowContainerId,
  selectedDates,
}: ConversionsProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['conversionRates', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .conversionRates({
          input: { ...selectedDates, flowContainer: flowContainerId },
        })
        .then((res) => res.conversionRates),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Card title="Flow conversion rate">
      <Card.Section>
        {isLoading || !data ? (
          <Stack vertical>
            <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={4} />
          </Stack>
        ) : (
          <Stack vertical>
            <NumberComparison
              current={data.converted.percentage}
              typeOfCurrent="percentage"
              percentage={data.converted.changePercentage}
            />

            <Subheading>Conversion funnel</Subheading>

            <table style={{ width: '100%' }}>
              <tbody>
                {funnelSteps.map(({ step, label }) => (
                  <tr key={step}>
                    <td colSpan={2} style={{ paddingBottom: '8px' }}>
                      <p>{label}</p>
                      <TextStyle variation="subdued">
                        {data[step].count} sessions
                      </TextStyle>
                    </td>
                    <td
                      colSpan={2}
                      style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                      {round(data[step].percentage)}%
                    </td>
                    <td
                      colSpan={2}
                      style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                      <Stack
                        alignment="center"
                        spacing="extraTight"
                        distribution="trailing"
                        wrap={false}
                      >
                        {data[step].changePercentage !== 0 &&
                          data[step].changePercentage !== null && (
                            <Icon
                              color={
                                data[step].changePercentage > 0
                                  ? 'success'
                                  : 'critical'
                              }
                              source={
                                data[step].changePercentage > 0
                                  ? ArrowUpMinor
                                  : ArrowDownMinor
                              }
                            />
                          )}
                        <>
                          {data[step].changePercentage === 0 ||
                          data[step].changePercentage === null
                            ? '–'
                            : `${Math.round(data[step].changePercentage)}%`}
                        </>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Stack>
        )}
      </Card.Section>
    </Card>
  );
};
