import { createContext, FC, useContext, useState } from 'react';
import { isBefore } from 'date-fns';
import { PLANS_ENUM } from '~/components';
import BillingModal from '~/components/BillingModal';
import { useStore } from '~/hooks';

interface ContextProps {
  setBillingModalOpen(arg: boolean): void;
}

const BillingAlertContext = createContext<ContextProps | undefined>(undefined);

export const BillingProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <BillingAlertContext.Provider
      value={{
        setBillingModalOpen: setIsModalOpen,
      }}
    >
      {children}
      {
        <BillingModal
          open={isModalOpen}
          setClose={() => setIsModalOpen(false)}
        />
      }
    </BillingAlertContext.Provider>
  );
};

const useGetBillingInfo = () => {
  const context = useContext(BillingAlertContext);
  const store = useStore();
  const storePlan = store?.tier;

  let isExistingV1User = false;
  if (process.env.REACT_APP_LAUNCH_V2_DATE) {
    const createAtDate = new Date(store?.createdAt);
    const launchDate = new Date(process.env.REACT_APP_LAUNCH_V2_DATE);
    isExistingV1User = isBefore(createAtDate, launchDate);
  }
  const isCurrentBillingVersion = storePlan?.billingVersion === 1;
  const isPaidPlan =
    isCurrentBillingVersion && storePlan?.name !== PLANS_ENUM.BASIC;

  return {
    context,
    isCurrentBillingVersion,
    isPaidPlan,
    isExistingV1User,
  };
};

export const useBillingWrapper = () => {
  const { context, isCurrentBillingVersion, isPaidPlan } = useGetBillingInfo();

  return (fn) => async (args?) => {
    const hasAccess = !isCurrentBillingVersion || isPaidPlan;

    if (hasAccess) {
      await fn(args);
      return;
    } else {
      context?.setBillingModalOpen(true);
    }
  };
};
