import Flags from 'country-flag-icons/react/3x2';

export const Flag = ({ countryCode }) => {
  const FlagElement = Flags[countryCode];

  return FlagElement ? (
    <div style={{ width: '20px' }}>
      <FlagElement />
    </div>
  ) : null;
};
