import { DisplayText, Icon, Stack, TextStyle } from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';
import { useStore } from '~/hooks';

import { formatPrice, getPercentage, round } from '~/lib';

interface NumberComparisonProps {
  current: number;
  typeOfCurrent?: 'number' | 'percentage' | 'price';
  previous?: number;
  percentage?: number | null;
}

export const NumberComparison = ({
  current,
  typeOfCurrent,
  previous,
  percentage,
}: NumberComparisonProps) => {
  const store = useStore();

  return (
    <Stack distribution="equalSpacing">
      <DisplayText size="large">
        {typeOfCurrent === 'percentage'
          ? round(current)
          : typeOfCurrent === 'price'
          ? formatPrice(current, store?.currencyCode)
          : current}
        {typeOfCurrent === 'percentage' && '%'}
      </DisplayText>

      <TextStyle
        variation={
          (
            typeof percentage !== 'undefined' && percentage !== null
              ? percentage > 0
              : previous && current > previous
          )
            ? 'positive'
            : (
                typeof percentage !== 'undefined' && percentage !== null
                  ? percentage < 0
                  : current < (previous || 0)
              )
            ? 'negative'
            : 'subdued'
        }
      >
        {previous ||
        (typeof percentage !== 'undefined' &&
          percentage !== null &&
          percentage !== 0) ? (
          <Stack alignment="center" spacing="extraTight">
            {(typeof percentage !== 'undefined'
              ? percentage !== 0
              : current !== previous) && (
              <Icon
                color={
                  (
                    typeof percentage !== 'undefined' && percentage !== null
                      ? percentage > 0
                      : current > (previous || 0)
                  )
                    ? 'success'
                    : (
                        typeof percentage !== 'undefined' && percentage !== null
                          ? percentage < 0
                          : current < (previous || 0)
                      )
                    ? 'critical'
                    : 'subdued'
                }
                source={
                  (
                    typeof percentage !== 'undefined' && percentage !== null
                      ? percentage < 0
                      : current < (previous || 0)
                  )
                    ? ArrowDownMinor
                    : ArrowUpMinor
                }
              />
            )}
            <DisplayText size="large">
              {Math.abs(
                Math.round(
                  typeof percentage !== 'undefined' && percentage !== null
                    ? percentage
                    : getPercentage(current, previous || 0) || 0,
                ),
              )}
              %
            </DisplayText>
          </Stack>
        ) : (
          <DisplayText size="large">–</DisplayText>
        )}
      </TextStyle>
    </Stack>
  );
};
