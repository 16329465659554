import { Suspense, useEffect, useState } from 'react';
import { makeVar } from '@apollo/client';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import { ErrorPage, LazyLoad } from '~/components';
import config from '~/config';
import { StoreProvider } from '~/context';
import { SubscriptionStatus } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Router } from './Router';

const show = () => {
  const root = document.querySelector('#perfect-product-finder');

  if (root) {
    (root as any).style.opacity = 1;
  }

  const pageLoader = document.querySelector('#page-loader');

  if (pageLoader) {
    (pageLoader as any).style.opacity = 0;

    setTimeout(() => {
      (pageLoader as any).style.display = 'none';
    }, 120);
  }
};

export const currentQuizFlow = makeVar('');

export const App = ({ location }: RouteComponentProps) => {
  const { boot: bootIntercom } = useIntercom();
  const app = useAppBridge();
  const sdk = useSdk();

  const [hasCrashed, setCrashed] = useState(false);

  const subscriptionIsRequired = config.subscriptionIsRequired;

  const getStoreIsMissingSubscription = (
    store:
      | {
          isTest: boolean;
          subscriptionStatus: SubscriptionStatus;
        }
      | null
      | undefined,
  ) => {
    if (store?.isTest) return false; // dev stores don't need subscription
    const hasActiveSubscription =
      store?.subscriptionStatus === SubscriptionStatus.Active;
    return !hasActiveSubscription;
  };

  const { data, error } = useQuery(
    ['currentStore'],
    () => sdk.currentStore().then((res) => res.currentStore),
    {
      refetchOnWindowFocus: false,
      onSettled: (data, error) => {
        if (
          !error &&
          subscriptionIsRequired &&
          getStoreIsMissingSubscription(data)
        ) {
          if (location.pathname !== '/onboarding') {
            app.dispatch(Redirect.toApp({ path: '/onboarding' }));
            return;
          }
        }
        show();
      },
    },
  );

  useEffect(() => {
    if (!data) return;
    bootIntercom({
      userId: data.shopifyId,
      email: data.email || undefined,
      name: data.name || undefined,
    });
  }, [data]);

  if (!data && !error) {
    return null;
  }

  if (error || !data?.shopifyId /*|| subscriptionError*/) {
    return (
      <ErrorPage
        text="The app could not be loaded. Our team has been notified and is working to resolve the issue. Please try again soon."
        onReset={() => {
          window.location.reload();
        }}
      />
    );
  }

  if (
    subscriptionIsRequired &&
    getStoreIsMissingSubscription(data) &&
    location.pathname !== '/onboarding'
  ) {
    return null;
  }

  return (
    <StoreProvider currentStore={data}>
      <div>
        <Helmet {...config.seo} />

        <ErrorBoundary
          onReset={() => {
            setCrashed(true);

            window.location.reload();
          }}
          fallbackRender={({ resetErrorBoundary }) => (
            <ErrorPage onReset={resetErrorBoundary} />
          )}
        >
          {!hasCrashed && (
            <Suspense fallback={<LazyLoad />}>
              <Router />
            </Suspense>
          )}
        </ErrorBoundary>
      </div>
    </StoreProvider>
  );
};
