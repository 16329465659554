import { createContext, ReactNode } from 'react';
import { CurrentStoreQuery } from '~/graphql/sdk';

interface ProviderProps {
  children: ReactNode;
  currentStore: CurrentStoreQuery['currentStore'];
}

export const StoreContext = createContext<CurrentStoreQuery['currentStore']>(
  undefined,
);

export const StoreProvider = ({ children, currentStore }: ProviderProps) => (
  <StoreContext.Provider value={currentStore}>{children}</StoreContext.Provider>
);
