export const getPercentage = (numberNow: number, numberBefore: number) => {
  if (!numberBefore) {
    return undefined;
  }

  if (!numberNow) {
    return -100;
  }

  return ((numberNow - numberBefore) / numberBefore) * 100;
};
