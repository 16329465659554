import { ReactNode } from 'react';
import { Badge, Button, Card, Heading, Stack } from '@shopify/polaris';
import { useBillingWrapper } from '~/lib/billingAlert';

interface IntegrationCardProps {
  icon?: ReactNode;
  title: string;
  description: string | ReactNode;
  isLoading?: boolean;
  isConnected?: boolean;
  isBillingCheck?: boolean;
  onClick(): void;
}

export const IntegrationCard = ({
  icon,
  title,
  description,
  isLoading,
  isConnected,
  onClick,
  isBillingCheck,
}: IntegrationCardProps) => {
  const billingWrapper = useBillingWrapper();

  return (
    <Card sectioned>
      <Stack alignment="center">
        {icon}

        <Stack.Item fill>
          <Stack vertical spacing="tight">
            <Stack alignment="center" spacing="tight">
              <Heading>{title}</Heading>
              {isConnected && <Badge status="success">Connected</Badge>}
            </Stack>
            <p>{description}</p>
          </Stack>
        </Stack.Item>

        <Button
          loading={isLoading}
          primary={!isConnected}
          plain={isConnected}
          onClick={isBillingCheck ? billingWrapper(onClick) : onClick}
        >
          {isConnected ? 'Edit' : 'Connect'}
        </Button>
      </Stack>
    </Card>
  );
};
