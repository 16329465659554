import { useMemo } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useQuery } from 'react-query';

import { useSdk } from './useSdk';

const thisMonth = {
  start: startOfMonth(new Date()),
  end: endOfMonth(new Date()),
};

export const useUsage = () => {
  const sdk = useSdk();

  const { data: store } = useQuery(['currentStore'], () =>
    sdk.currentStore().then((res) => res.currentStore),
  );

  const { data: analyticsUsage, isLoading } = useQuery(
    ['analyticsUsage', thisMonth],
    () =>
      sdk
        .analyticsUsage({
          input: { ...thisMonth },
        })
        .then((res) => res.analyticsUsage.engagements),
    {
      refetchOnWindowFocus: false,
    },
  );

  const currentUsage = analyticsUsage || 0;

  const percentage = useMemo(
    () =>
      store?.tier.maxEngagements
        ? (currentUsage / store.tier.maxEngagements) * 100
        : 0,
    [currentUsage, store],
  );

  return { isLoading, percentage, usage: currentUsage };
};
