import { useMemo } from 'react';
import { Card, Spinner, Stack, Subheading } from '@shopify/polaris';
import { format } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { NumberComparison } from '~/components';
import { useStore } from '~/hooks';
import { formatPrice } from '~/lib';
import { formatDateRange, usePreviousDateRange } from '../DateRangePicker';

interface AverageNumbers {
  current: number;
  previous: number;
}

interface ChartProps {
  accessibilityLabel?: string;
  averageBy?: AverageNumbers;
  data: any;
  isLoading: boolean;
  prices?: boolean;
  selectedDates: any;
  subtitle: string;
  title: string;
}

export const Chart = ({
  averageBy,
  data,
  isLoading,
  prices,
  selectedDates,
  subtitle,
  title,
  accessibilityLabel,
}: ChartProps) => {
  const store = useStore();

  const dateRange = useMemo(
    () => formatDateRange(selectedDates.start, selectedDates.end),
    [selectedDates],
  );
  const compareTo = usePreviousDateRange(selectedDates);

  const chartData = useMemo(
    () =>
      data?.map(({ date, items }) => ({
        name: format(new Date(date), 'd MMM'),
        current: items?.find(({ key }) => key === 'current')?.value,
        previous: items?.find(({ key }) => key === 'previous')?.value,
      })) || [],
    [data],
  );

  const { current, previous } = useMemo(() => {
    const current =
      data?.reduce((total, { items }) => {
        total += items?.find(({ key }) => key === 'current')?.value || 0;
        return total;
      }, 0) || 0;
    const previous =
      data?.reduce((total, { items }) => {
        total += items?.find(({ key }) => key === 'previous')?.value || 0;
        return total;
      }, 0) || 0;

    return {
      current: averageBy ? current / averageBy.current : current,
      previous: averageBy ? previous / averageBy.previous : previous,
    };
  }, [data, averageBy]);

  return (
    <Card title={title}>
      {isLoading ? (
        <Card.Section>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '200px',
            }}
          >
            <Spinner accessibilityLabel={accessibilityLabel} size="large" />
          </div>
        </Card.Section>
      ) : (
        <>
          <Card.Section>
            <Stack vertical>
              <NumberComparison
                current={current}
                previous={previous}
                typeOfCurrent={prices ? 'price' : 'number'}
              />

              <Subheading>{subtitle}</Subheading>

              <ResponsiveContainer width="100%" height={220}>
                <LineChart
                  width={600}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="name" />
                  <YAxis allowDecimals={false} />
                  <Tooltip
                    formatter={(value) => {
                      if (prices) {
                        return formatPrice(value, store?.currencyCode);
                      }

                      return value;
                    }}
                  />
                  <Legend />
                  <Line
                    dataKey="previous"
                    name={compareTo}
                    stroke="#919eab"
                    strokeDasharray="5 5"
                    strokeWidth={3}
                    dot={false}
                    activeDot={{ r: 5 }}
                  />
                  <Line
                    dataKey="current"
                    name={dateRange}
                    stroke="#00a19f"
                    strokeWidth={3}
                    dot={false}
                    activeDot={{ r: 5 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Stack>
          </Card.Section>
        </>
      )}
    </Card>
  );
};
