import { lazy, Suspense } from 'react';
import { Page, Layout } from '@shopify/polaris';

import { LazyLoad } from '~/components';
import PricingPlansLoadable from '~/components/PricingPlans/PricingPlansLoadable';

const Component = lazy(() => import('~/containers/Billing/BillingV1'));

export default () => (
  <Suspense
    fallback={
      <LazyLoad>
        <Page>
          <Layout>
            <Layout.Section>
              <PricingPlansLoadable />
            </Layout.Section>
          </Layout>
        </Page>
      </LazyLoad>
    }
  >
    <Component />
  </Suspense>
);
