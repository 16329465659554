import createApp from '@shopify/app-bridge';
import { Provider } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { AppProvider, Frame } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import qs from 'query-string';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as Yup from 'yup';

import '@shopify/polaris/build/esm/styles.css';

import { Link } from '~/components';
import config from '~/config';
import { App } from '~/containers';
import { ShopProvider } from '~/context';
import { ToastProvider } from '~/lib';
import { BillingProvider } from '~/lib/billingAlert';
import { polyfill } from '~/lib/polyfills';

if (process.env.REACT_APP_SENTRY_DSN) {
  import('~/lib/sentry').then((m) => m.initSentry());
}

Yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    email: 'Invalid email',
  },
});

const shopOrigin = qs.parse(window.location.search?.split('?')?.[1])
  ?.shop as string;
const host =
  (qs.parse(window.location.search?.split('?')?.[1])?.host as string) ||
  window.btoa(shopOrigin + '/admin');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const render = () => {
  ReactDOM.render(
    <BrowserRouter>
      <AppProvider linkComponent={Link} i18n={translations}>
        <Provider
          config={{
            apiKey: config.apiKey as string,
            host,
            forceRedirect: true,
          }}
        >
          <ShopProvider shop={shopOrigin}>
            <QueryClientProvider client={queryClient}>
              <IntercomProvider appId={config.intercomAppId}>
                <Frame>
                  <ToastProvider>
                    <BillingProvider>
                      <Switch>
                        <Route component={App} />
                      </Switch>
                    </BillingProvider>
                  </ToastProvider>
                </Frame>
              </IntercomProvider>

              <ReactQueryDevtools />
            </QueryClientProvider>
          </ShopProvider>
        </Provider>
      </AppProvider>
    </BrowserRouter>,
    document.getElementById('perfect-product-finder'),
  );
};

const checkSession = () =>
  new Promise<void>(async (resolve) => {
    try {
      await polyfill();

      if (!qs.parse(window.location.search)?.hmac) {
        resolve();
        return;
      }

      const { hasAccessToken } = await fetch(
        `${config.apiUrl}/auth/verify${window.location.search}`,
      ).then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      });

      if (!hasAccessToken) {
        if (!shopOrigin) {
          throw new Error();
        }

        const redirectUrl = `${config.apiUrl}/auth/login/${shopOrigin}`;

        if (window.top === window.self) {
          window.location.href = redirectUrl;
        } else {
          const app = createApp({
            apiKey: config.apiKey as string,
            host,
          });

          Redirect.create(app).dispatch(Redirect.Action.REMOTE, redirectUrl);
        }
        return;
      }

      resolve();
    } catch (e) {
      const root = document.getElementById('page-loader');

      if (root) {
        root.innerHTML = `<div class="full-page"><p>Error loading app, please try again soon.<br /><br /><a href="#" onclick="window.location.reload()">Reload app</a></p></div>`;
      }
    }
  });

checkSession().then(render);
