import { forwardRef } from 'react';
import { Banner, List } from '@shopify/polaris';

import { getErrorLength } from '~/lib';

interface ErrorBannerProps {
  error: any;
  /** @default 'An error has occured' */
  title?: string;
}

export const ErrorBanner = forwardRef(
  ({ error, title = 'An error has occured' }: ErrorBannerProps, ref: any) => {
    if (!error) {
      return null;
    }

    const messages = getErrorLength(error);

    return (
      <Banner title={title} status="critical" ref={ref}>
        <p>
          {messages?.length > 1 ? (
            <List type="bullet">
              {messages.map((message) => (
                <List.Item key={message}>{message}</List.Item>
              ))}
            </List>
          ) : (
            messages?.[0] || error?.message
          )}
        </p>
      </Banner>
    );
  },
);
