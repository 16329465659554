import styles from './styles.module.scss';

const Brands = () => (
  <div className={styles.brands}>
    Trusted by 1000+ brands including:
    <img src="/logos/volcom-logo.png" alt="Volcom logo" height="34" />
    <img src="/logos/hoover-logo.png" alt="Hoover logo" height="34" />
    <img src="/logos/dewalt-logo.png" alt="Dewalt logo" height="34" />
    <img src="/logos/motorola-logo.png" alt="Motorola logo" height="34" />
    <img
      src="/logos/LOccitane-logo.svg"
      alt="L'Occitane El Salvador logo"
      height="34"
    />
  </div>
);

export default Brands;
