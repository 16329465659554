import { lazy, Suspense } from 'react';
import {
  Page,
  Layout,
  Card,
  Heading,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextContainer,
} from '@shopify/polaris';

import { range } from 'lodash';
import { LazyLoad } from '~/components';

const Component = lazy(() => import('~/containers/Billing/BillingV0'));

export default () => (
  <Suspense
    fallback={
      <LazyLoad>
        <Page>
          <Layout>
            <Layout.Section fullWidth>
              <Heading>Status</Heading>
            </Layout.Section>

            {range(2).map((idx) => (
              <Layout.Section oneHalf key={idx}>
                <Card sectioned>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonDisplayText size="extraLarge" />
                  </TextContainer>
                </Card>
              </Layout.Section>
            ))}

            <Layout.Section fullWidth>
              <Heading>Available plans</Heading>
            </Layout.Section>

            {range(3).map((idx) => (
              <Layout.Section oneThird key={idx}>
                <Card sectioned>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                </Card>
              </Layout.Section>
            ))}

            <Layout.Section>
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </LazyLoad>
    }
  >
    <Component />
  </Suspense>
);
