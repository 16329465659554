import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { Chart } from './Chart';

interface AverageOrderValueProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const AverageOrderValue = ({
  flowContainerId,
  selectedDates,
}: AverageOrderValueProps) => {
  const sdk = useSdk();

  const input = {
    ...selectedDates,
    flowContainer: flowContainerId,
    compareTo: CompareTo.PreviousPeriod,
  };

  const { data, isLoading } = useQuery(
    [
      'analyticsRevenueAverageOrderValue',
      { ...selectedDates, flowContainerId },
    ],
    () =>
      sdk
        .analyticsRevenueAverageOrderValue({
          input,
        })
        .then((res) => res.analyticsRevenueAverageOrderValue),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: orderData, isLoading: orderDataLoading } = useQuery(
    ['analyticsRevenueOrders', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsRevenueOrders({
          input,
        })
        .then((res) => res.analyticsRevenueOrders),
    {
      refetchOnWindowFocus: false,
    },
  );

  const averageBy = useMemo(() => {
    if (!orderData) {
      return undefined;
    }

    return {
      current:
        orderData?.reduce((total, { items }) => {
          total += items?.find(({ key }) => key === 'current')?.value ? 1 : 0;
          return total;
        }, 0) || 1,
      previous:
        orderData?.reduce((total, { items }) => {
          total += items?.find(({ key }) => key === 'previous')?.value ? 1 : 0;
          return total;
        }, 0) || 1,
    };
  }, [orderData]);

  return (
    <Chart
      data={data}
      isLoading={isLoading || orderDataLoading}
      selectedDates={selectedDates}
      accessibilityLabel="Loading average order value analytics"
      subtitle="Order value over time"
      title="Average order value"
      prices
      averageBy={averageBy}
    />
  );
};
