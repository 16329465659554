import {
  useClientRouting,
  useRoutePropagation,
} from '@shopify/app-bridge-react';
import { Frame } from '@shopify/polaris';
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';

import { TabBar } from '~/components';
import {
  Analytics,
  AnalyticsSession,
  AnalyticsSubmission,
  Billing,
  FlowsOverview,
  Home,
  Integrations,
  LanguagesOverview,
  LanguagesSingle,
  NotFound,
  Onboarding,
} from '~/containers';
import styles from './styles.module.scss';

export const Router = withRouter(
  ({
    history,
    location,
  }: Pick<RouteComponentProps, 'location' | 'history'>) => {
    useClientRouting(history);
    useRoutePropagation(location);

    return (
      <div className={styles.wrapper}>
        <Switch location={location}>
          <Route exact path="/onboarding" component={Onboarding} />
          <Frame topBar={<TabBar />}>
            <Route exact path="/" component={Home} />
            <Route exact path="/analytics" component={Analytics} />
            <Route
              exact
              path="/analytics/sessions"
              component={AnalyticsSession}
            />
            <Route
              exact
              path="/analytics/submissions"
              component={AnalyticsSubmission}
            />
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/flows" component={FlowsOverview} />
            <Route exact path="/integrations" component={Integrations} />
            <Route exact path="/languages" component={LanguagesOverview} />
            <Route exact path="/languages/:id" component={LanguagesSingle} />
          </Frame>
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  },
);
