import AnalyticPageContainer, {
  EnumAnalyticPages,
} from '~/components/AnalyticsCommon';
import SubmissionTable from '~/components/SubmissionsTable';

export const AnalyticsSubmission = () => (
  <AnalyticPageContainer
    title={EnumAnalyticPages.SUBMISSIONS}
    AnalyticComponent={SubmissionTable}
    isAllEnable={false}
  />
);

export default AnalyticsSubmission;
