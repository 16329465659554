import { lazy, Suspense } from 'react';
import { Page, Layout } from '@shopify/polaris';
import { LazyLoad } from '~/components';
import PricingPlansLoadable from '~/components/PricingPlans/PricingPlansLoadable';
import Brands from '~/containers/Onboarding/Brands';
import styles from '~/containers/Onboarding/styles.module.scss';

const Component = lazy(() => import('~/containers/Onboarding'));

export default () => (
  <Suspense
    fallback={
      <LazyLoad>
        <div className={styles.topBar}>
          <img
            src="/logos/ppf.svg"
            alt="Perfect product finder logo"
            height="34"
            className={styles.logo}
          />
          <Brands />
        </div>
        <Page fullWidth>
          <div className={styles.onboarding}>
            <Layout>
              <Layout.Section>
                <h3 className={styles.title}>
                  Welcome to the Perfect Product Finder{' '}
                  <span role="img" aria-label={'Confetti Ball'}>
                    🎊
                  </span>
                  !
                </h3>
              </Layout.Section>
              <Layout.Section>
                <p className={styles.subtitle}>
                  Choose your plan (you can switch later):
                </p>
              </Layout.Section>
              <Layout.Section>
                <PricingPlansLoadable />
              </Layout.Section>
            </Layout>
          </div>
        </Page>
      </LazyLoad>
    }
  >
    <Component />
  </Suspense>
);
