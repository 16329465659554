import {
  Badge,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import styles from '~/components/PricingPlans/styles.module.scss';

const Loadable = () => {
  return (
    <div className={styles.plansWrapper}>
      <div className={styles.cardContainer}>
        <Card
          title={
            <h2 className={'Polaris-Heading'}>
              Grows <Badge status="info">Most Popular</Badge>{' '}
            </h2>
          }
        >
          <Card.Section>
            <SkeletonDisplayText size="large" />
          </Card.Section>
          <Card.Section>
            <SkeletonBodyText />
          </Card.Section>
          <Card.Section>
            <SkeletonBodyText lines={10} />
          </Card.Section>
          <Card.Section>
            <SkeletonDisplayText size="medium" />
          </Card.Section>
        </Card>
      </div>
      <div className={styles.cardContainer}>
        <Card title="Basic">
          <Card.Section>
            <SkeletonDisplayText size="large" />
          </Card.Section>
          <Card.Section>
            <SkeletonBodyText />
          </Card.Section>
          <Card.Section>
            <SkeletonBodyText lines={10} />
          </Card.Section>
          <Card.Section>
            <SkeletonDisplayText size="medium" />
          </Card.Section>
        </Card>
      </div>
    </div>
  );
};

export default Loadable;
