import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import {
  Button,
  Select,
  Card,
  Page,
  Layout,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import qs from 'query-string';
import { useQuery } from 'react-query';

import { DateRange, DateRangePicker } from '~/components';
import config from '~/config';
import { currentQuizFlow } from '~/containers';
import {
  FlowContainerStatus,
  FlowFragmentFragment,
  OttAction,
} from '~/graphql/sdk';
import { useSdk } from '~/hooks';
import { useToast } from '~/lib';
import { useBillingWrapper } from '~/lib/billingAlert';
import AnalyticNavMenu from './AnalyticNavMenu';
import styles from './styles.module.scss';

const options = [{ label: 'All', value: '' }];
const optionsWithoutAll = [{ label: '', value: '' }];

export enum EnumAnalyticPages {
  METRIC = 'Metric',
  SESSIONS = 'Sessions',
  SUBMISSIONS = 'Submissions',
}

export interface IAnalyticsComponent {
  flows: Array<{ __typename?: 'Flow' } & FlowFragmentFragment>;
  flowContainerId?: string;
  isAll?: boolean;
  dateFilter?: DateRange;
}

interface IAnalyticPageContainerProps {
  AnalyticComponent: FC<IAnalyticsComponent>;
  title: EnumAnalyticPages;
  isAllEnable?: boolean;
  dateFilterCompared?: boolean;
}

const AnalyticPageContainer: FC<IAnalyticPageContainerProps> = ({
  AnalyticComponent,
  title,
  isAllEnable = true,
  dateFilterCompared = false,
}) => {
  const app = useAppBridge();
  const sdk = useSdk();
  const billingWrapper = useBillingWrapper();
  const [isDownloading, setDownloading] = useState(false);
  const toast = useToast();
  const commonCurrentQuiz = useReactiveVar(currentQuizFlow);
  const [checkedFlow, setCheckedFlow] = useState(
    commonCurrentQuiz ||
      (isAllEnable ? options[0].value : optionsWithoutAll[0].value),
  );
  const [selectedDates, setSelectedDates] = useState({
    start: startOfDay(subDays(new Date(), 29)),
    end: endOfDay(new Date()),
  });

  const { data } = useQuery(
    ['containersExtended'],
    () =>
      sdk
        .containersExtended({
          filter: { status: { eq: FlowContainerStatus.Published } },
        })
        .then((res) => res.flowContainers),
    { suspense: true },
  );

  const currentFlow = useMemo(() => {
    if (!checkedFlow) return [];

    const checkedFlowEdge = data?.edges.find(
      (edge) => edge.node.id === checkedFlow,
    );

    return checkedFlowEdge?.node.flows || [];
  }, [checkedFlow]);

  const handleCheckedFlowChange = useCallback((value: string) => {
    setCheckedFlow(value);
    currentQuizFlow(value);
  }, []);

  const flowContainerOptions =
    data?.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id,
    })) || [];

  const downloadExport = async () => {
    if (isDownloading) {
      return;
    }

    if (!currentFlow?.length && checkedFlow !== options[0].value) {
      toast({ content: 'Flow subscriptions are not exist' });
      return;
    }

    setDownloading(true);

    try {
      const token = await sdk
        .accessToken({ action: OttAction.ExportEmails })
        .then((res) => res.accessToken);

      let url = '';

      if (title === EnumAnalyticPages.SESSIONS) {
        url = checkedFlow === options[0].value ? 'emails-all' : 'emails';
      } else if (title === EnumAnalyticPages.SUBMISSIONS) {
        url = 'submissions';
      }

      app.dispatch(
        Redirect.toRemote({
          url: `${config.apiUrl}/session/export/${url}?${qs.stringify({
            ott: token,
            ...(checkedFlow ? { flowContainerId: checkedFlow } : {}),
            ...(title === EnumAnalyticPages.SUBMISSIONS
              ? {
                  startDate: selectedDates.start.getTime(),
                  endDate: selectedDates.end.getTime(),
                }
              : {}),
          })}`,
          newContext: true,
        }),
      );
    } catch (e) {
      toast({ content: 'Failed to download export', error: true });
    }

    setDownloading(false);
  };

  const billedDownloadExport =
    title === EnumAnalyticPages.SUBMISSIONS
      ? billingWrapper(downloadExport)
      : downloadExport;

  useEffect(() => {
    if (!checkedFlow && typeof checkedFlow !== 'string') {
      handleCheckedFlowChange(flowContainerOptions?.[0]?.value);
    }
  }, [data]);

  return (
    <div className={styles.analyticWrapper}>
      <Page fullWidth>
        <Layout>
          <AnalyticNavMenu />
          <Layout.Section>
            <Card title={title}>
              <Card.Section>
                <div className={styles.underTableBar}>
                  <div className={styles.filters}>
                    {!!data || isAllEnable ? (
                      <>
                        <Select
                          label={''}
                          options={[
                            ...(isAllEnable ? options : optionsWithoutAll),
                            ...flowContainerOptions,
                          ]}
                          value={checkedFlow || ''}
                          onChange={handleCheckedFlowChange}
                        />
                        <DateRangePicker
                          selectedDates={selectedDates}
                          setSelectedDates={setSelectedDates}
                          compared={dateFilterCompared}
                        />
                      </>
                    ) : (
                      <>
                        <SkeletonDisplayText size={'small'} />
                        <SkeletonDisplayText size={'small'} />
                      </>
                    )}
                  </div>
                  {[
                    EnumAnalyticPages.SUBMISSIONS,
                    EnumAnalyticPages.SESSIONS,
                  ].includes(title) && (
                    <Button
                      primary
                      disabled={
                        !data ||
                        (!isAllEnable &&
                          checkedFlow === optionsWithoutAll[0].value)
                      }
                      onClick={billedDownloadExport}
                    >
                      Export to CSV
                    </Button>
                  )}
                </div>
              </Card.Section>
            </Card>
            {
              <AnalyticComponent
                flows={currentFlow}
                isAll={!checkedFlow}
                dateFilter={selectedDates}
                flowContainerId={checkedFlow ? checkedFlow : ''}
              />
            }
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};

export default AnalyticPageContainer;
