import { useQuery } from 'react-query';

import { CompareTo } from '~/graphql/sdk';
import { useSdk } from '~/hooks';

import { ProductsTable } from './ProductsTable';

interface TopRecommendedProps {
  flowContainerId?: string;
  selectedDates: any;
}

export const TopRecommended = ({
  flowContainerId,
  selectedDates,
}: TopRecommendedProps) => {
  const sdk = useSdk();

  const { data, isLoading } = useQuery(
    ['analyticsTopRecommended', { ...selectedDates, flowContainerId }],
    () =>
      sdk
        .analyticsTopRecommended({
          input: {
            ...selectedDates,
            flowContainer: flowContainerId,
            compareTo: CompareTo.PreviousPeriod,
          },
        })
        .then((res) => res.analyticsTopRecommended),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <ProductsTable
      data={data}
      isLoading={isLoading}
      title="Top recommended products"
      noResultsText="There were no products recommended in this date range."
      type="recommended"
    />
  );
};
