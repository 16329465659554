import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from '@shopify/app-bridge-utils';

import { getSdk } from '~/lib';

export const useSdk = () => {
  const app = useAppBridge();

  return getSdk(authenticatedFetch(app));
};
